import { index } from "./components/index";

index();

$(function () {

	$(".openbtn").click(function () {//ボタンがクリックされたら
		$(this).toggleClass('active');//ボタン自身に activeクラスを付与し
		$("#g-nav").toggleClass('panelactive');//ナビゲーションにpanelactiveクラスを付与
		$("#g-nav2").toggleClass('panelactive');//ナビゲーションにpanelactiveクラスを付与
		$(".p-navBg").toggleClass('panelactive');//ナビゲーションにpanelactiveクラスを付与
		$(".closeBtn").toggleClass('active');//ナビゲーションにpanelactiveクラスを付与
		$('html').toggleClass('scroll-prevent');
	});

	$("#g-nav a").click(function () {//ナビゲーションのリンクがクリックされたら
		$(".openbtn").removeClass('active');//ボタンの activeクラスを除去し
		$("#g-nav").removeClass('panelactive');//ナビゲーションのpanelactiveクラスも除去
		$("#g-nav2").removeClass('panelactive');//ナビゲーションのpanelactiveクラスも除去
		$(".p-navBg").removeClass('panelactive');//ナビゲーションのpanelactiveクラスも除去
	});
	$(".closeBtn").click(function () {//ナビゲーションのリンクがクリックされたら
		$(".openbtn").removeClass('active');//ボタンの activeクラスを除去し
		$("#g-nav").removeClass('panelactive');//ナビゲーションのpanelactiveクラスも除去
		$("#g-nav2").removeClass('panelactive');//ナビゲーションのpanelactiveクラスも除去
		$(".p-navBg").removeClass('panelactive');//ナビゲーションのpanelactiveクラスも除去
		$(".closeBtn").toggleClass('active');//ナビゲーションにpanelactiveクラスを付与
	});
})
