// const moment = require('moment');
// import { gsap } from "gsap";
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
// import { Navigation, Pagination, Autoplay, EffectFade, Swiper } from 'swiper'
// Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);
// gsap.registerPlugin(ScrollTrigger);

import slick from 'slick-carousel';

export function index() {

	$(function () {
		$(".regular_3").slick({
			dots: true, // ドットインジケーターの表示
			infinite: true, // スライドのループを有効にするか
			slidesToShow: 4, // 表示するスライド数を設定
			slidesToScroll: 1, // スクロールするスライド数を設定
			adaptiveHeight: true,
			responsive: [
				{
					breakpoint: 1920,
					settings: {
						slidesToShow: 4,
					}
				},
				{
					breakpoint: 1500,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 1440,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
					}
				},
				{
					breakpoint: 376,
					settings: {
						slidesToShow: 1,
					}
				},
			],
			prevArrow: "<button class=\"p-sliderContainer__btn p-sliderContainer__btn--right c-btn--rightSemicircle c-btn--borderOrange\">\n" +
				"                        <i class=\"p-sliderContainer__arrow\">\n" +
				"                        <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"32\" height=\"19.996\" viewBox=\"0 0 32 19.996\">\n" +
				"                            <g id=\"グループ_202\" data-name=\"グループ 202\" transform=\"translate(-2148.913 -7636.023)\">\n" +
				"                                <path id=\"パス_2\" data-name=\"パス 2\" d=\"M2065.25,7680.739h-19.891a1.447,1.447,0,1,1,0-2.893h19.891a1.447,1.447,0,1,1,0,2.893Z\" transform=\"translate(105 -33.271)\" fill=\"#382f2c\"/>\n" +
				"                                <path id=\"パス_3\" data-name=\"パス 3\" d=\"M2074.987,7686.019a1.446,1.446,0,0,1-1.023-2.469l7.528-7.528-7.528-7.528a1.447,1.447,0,0,1,2.046-2.047l8.551,8.552a1.447,1.447,0,0,1,0,2.046l-8.551,8.551A1.439,1.439,0,0,1,2074.987,7686.019Z\" transform=\"translate(95.929 -30)\" fill=\"#382f2c\"/>\n" +
				"                            </g>\n" +
				"                        </svg>\n" +
				"                        </i>\n" +
				"                    </button>",
			nextArrow: "<button class=\"p-sliderContainer__btn p-sliderContainer__btn--left c-btn--leftSemicircle c-btn--borderOrange\">\n" +
				"                        <i class=\"p-sliderContainer__arrow p-sliderContainer__arrow--flipHorizontal\">\n" +
				"                        <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"32\" height=\"19.996\" viewBox=\"0 0 32 19.996\">\n" +
				"                            <g id=\"グループ_202\" data-name=\"グループ 202\" transform=\"translate(-2148.913 -7636.023)\">\n" +
				"                                <path id=\"パス_2\" data-name=\"パス 2\" d=\"M2065.25,7680.739h-19.891a1.447,1.447,0,1,1,0-2.893h19.891a1.447,1.447,0,1,1,0,2.893Z\" transform=\"translate(105 -33.271)\" fill=\"#382f2c\"/>\n" +
				"                                <path id=\"パス_3\" data-name=\"パス 3\" d=\"M2074.987,7686.019a1.446,1.446,0,0,1-1.023-2.469l7.528-7.528-7.528-7.528a1.447,1.447,0,0,1,2.046-2.047l8.551,8.552a1.447,1.447,0,0,1,0,2.046l-8.551,8.551A1.439,1.439,0,0,1,2074.987,7686.019Z\" transform=\"translate(95.929 -30)\" fill=\"#382f2c\"/>\n" +
				"                            </g>\n" +
				"                        </svg>\n" +
				"                        </i>\n" +
				"                    </button>",
			centerMode: true,
			// centerPadding: '10px',
			mobileFirst: false,
		});
	});

	// $(document).on('slider_tablet', function () {
	//     $(".regular_4").slick({
	//         dots: true, // ドットインジケーターの表示
	//         infinite: true, // スライドのループを有効にするか
	//         slidesToShow: 2, // 表示するスライド数を設定
	//         slidesToScroll: 1, // スクロールするスライド数を設定
	//         prevArrow: "<button class=\"p-sliderContainer__btn p-sliderContainer__btn--right c-btn--rightSemicircle c-btn--borderOrange\">\n" +
	//             "                        <i class=\"p-sliderContainer__arrow\">\n" +
	//             "                        <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"32\" height=\"19.996\" viewBox=\"0 0 32 19.996\">\n" +
	//             "                            <g id=\"グループ_202\" data-name=\"グループ 202\" transform=\"translate(-2148.913 -7636.023)\">\n" +
	//             "                                <path id=\"パス_2\" data-name=\"パス 2\" d=\"M2065.25,7680.739h-19.891a1.447,1.447,0,1,1,0-2.893h19.891a1.447,1.447,0,1,1,0,2.893Z\" transform=\"translate(105 -33.271)\" fill=\"#382f2c\"/>\n" +
	//             "                                <path id=\"パス_3\" data-name=\"パス 3\" d=\"M2074.987,7686.019a1.446,1.446,0,0,1-1.023-2.469l7.528-7.528-7.528-7.528a1.447,1.447,0,0,1,2.046-2.047l8.551,8.552a1.447,1.447,0,0,1,0,2.046l-8.551,8.551A1.439,1.439,0,0,1,2074.987,7686.019Z\" transform=\"translate(95.929 -30)\" fill=\"#382f2c\"/>\n" +
	//             "                            </g>\n" +
	//             "                        </svg>\n" +
	//             "                        </i>\n" +
	//             "                    </button>",
	//         nextArrow: "<button class=\"p-sliderContainer__btn p-sliderContainer__btn--left c-btn--leftSemicircle c-btn--borderOrange\">\n" +
	//             "                        <i class=\"p-sliderContainer__arrow p-sliderContainer__arrow--flipHorizontal\">\n" +
	//             "                        <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"32\" height=\"19.996\" viewBox=\"0 0 32 19.996\">\n" +
	//             "                            <g id=\"グループ_202\" data-name=\"グループ 202\" transform=\"translate(-2148.913 -7636.023)\">\n" +
	//             "                                <path id=\"パス_2\" data-name=\"パス 2\" d=\"M2065.25,7680.739h-19.891a1.447,1.447,0,1,1,0-2.893h19.891a1.447,1.447,0,1,1,0,2.893Z\" transform=\"translate(105 -33.271)\" fill=\"#382f2c\"/>\n" +
	//             "                                <path id=\"パス_3\" data-name=\"パス 3\" d=\"M2074.987,7686.019a1.446,1.446,0,0,1-1.023-2.469l7.528-7.528-7.528-7.528a1.447,1.447,0,0,1,2.046-2.047l8.551,8.552a1.447,1.447,0,0,1,0,2.046l-8.551,8.551A1.439,1.439,0,0,1,2074.987,7686.019Z\" transform=\"translate(95.929 -30)\" fill=\"#382f2c\"/>\n" +
	//             "                            </g>\n" +
	//             "                        </svg>\n" +
	//             "                        </i>\n" +
	//             "                    </button>",
	//         centerMode: false
	//     });
	// });
}
